<template>
  <div>
    <div v-if="hasAuthority('brand_hub_brand_list')">
      <router-view></router-view>
      <search-page :type="'brand'" @handleQuery='handleQuery' @handelAdd='handelAdd'></search-page>
      <div class='default-table radius_bg'>
        <el-table :data='dataList' ref='groupBonusTable'
                  row-key='id'
                  :row-style="{height: '10px'}"
                  highlight-current-row
                  @row-dblclick='expandRow'
                  @expand-change='expandFun'
                  :expand-row-keys='expands'
                 v-loading='isLoading'>
          <el-table-column align='center' label='序号' type='index' width='60'></el-table-column>
          <el-table-column align='center' prop='name' show-overflow-tooltip label='品牌'
                           >
            <template slot-scope='{row}'>
              <el-link @click='linkInfo(row.id)' size='mini'
                       v-if="userPermissions.indexOf('brand_hub_brand_info') !== -1" type='primary'>
                <span class='brand'>{{ row.name }}</span>
              </el-link>
              <span class='brand' v-else>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' prop='leader_name' show-overflow-tooltip label='logo'>
            <template slot-scope='{row}'>
              <el-image
                style='width: 30px; height: 30px'
                :src='row.logo'
                :preview-src-list='[row.logo]'>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column align='center' prop='company_name'  show-overflow-tooltip label='归属公司'>
          </el-table-column>
          <el-table-column align='center' prop='commit_count' label='关联产品数量'>
          </el-table-column>
          <el-table-column align='center' prop='created_at' show-overflow-tooltip
                           label='录入时间'></el-table-column>
          <el-table-column align='center' width='110' prop='submit_name' label='负责人'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' prop='remark' label='备注信息'></el-table-column>
          <el-table-column label='操作' align='center' fixed='right'>
            <template slot-scope='{ row }'>
              <el-button size='default' type='text' @click='handleEdit(row)' v-has-authority="'brand_hub_brand_update'"
                         class='el-icon-edit'>修改
              </el-button>
              <el-button size='default' type='text' @click='handleDel(row)' v-has-authority="'brand_hub_brand_delete'"
                         class='el-icon-delete'>删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row >
          <el-col :span='24'>
            <Pagination :limit.sync='pagingData.page_size'
                        :page.sync='pagingData.current_page'
                        :total='pagingData.total'
                        @pagination='getList' />
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog :visible.sync='dialogStore'
               :title="`品牌库-${custom_type==='add'?'添加':'修改'}品牌信息`"
               :before-close='formatDate' width='880px' center>
      <el-row :gutter='20' style='border: 1px dashed red; padding: 20px'>
        <el-col :span='15'>
          <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right'
                   label-width='110px'>
            <el-form-item label='品牌' prop='name'>
              <el-row :gutter='10'>
                <el-col :span='16'>
                  <el-input v-model='formStore.name' clearable placeholder='品牌名字' @change='hasBrand' >
                    <template slot='append'>
                    </template>
                  </el-input>
                </el-col>
                <el-col :span='7'>
                  <span class='el-icon-close' style='color: #F56C6C' v-if='hasBrandName'></span>
                  <span class='el-icon-check' style='color: #67C23A'  v-else></span>
                  <span style='color: #F56C6C'>{{ hasBrandName ? '品牌名重复' : '' }}</span>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label='归属公司'>
              <el-input v-model='formStore.company_name' clearable placeholder='归属公司' @blur='hasBrand' />
            </el-form-item>
            <el-form-item label='备注' prop='remark'>
              <el-input type='textarea' v-model='formStore.remark' clearable></el-input>
            </el-form-item>
            <el-form-item label='负责人' prop='submit_id'>
              <el-select
                @change="setSelectValue('submit')"
                v-model='formStore.submit_name'
                filterable
                clearable
                remote
                reserve-keyword
                placeholder='输入关键字，搜索负责人'
                :remote-method='remoteMethod'
                :loading='selectLoading'>
                <el-option
                  v-for='item in options'
                  :key='item.id'
                  :label='item.nickname'
                  :value='item.id'>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span='7'>
          <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                       @handleUploadRemove='handleUploadRemove'
                       @handleUploadSuccess='handleUploadSuccess'
                       uploadTip='请上传图片，最多上传1份。大小限制2M文件'></ApeUploader>
        </el-col>
      </el-row>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import searchPage from './searchPage'
import ApeUploader from '@/components/ApeUploader'
import { hasAuthority } from '@/utils/hasAuthority'

const rules = {
  name: [
    { required: true, message: '请输入品牌名', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ]

}
export default {
  name: 'investment',
  data() {
    return {
      formData: { date: '' },
      dataList: [],
      pagingData: {
        page_size: 15,
        current_page: 1,
        total: 0
      },
      downloadLoading: false,
      isLoading: false,
      dialogStore: false,
      formStore: {
        name: null
      },
      rules: rules,
      imageUrl: '',
      uploadFileList: [],
      selectLoading: false,
      options: [],
      custom_type: 'add',
      brandOptions: [],
      contactList: [],
      searchData: {},
      expands: [],
      hasBrandName: false
    }
  },

  components: {
    ExportBtn, searchPage, ApeUploader
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  methods: {
    formatDate() {
      this.formStore = {}
      this.uploadFileList = []
      this.dialogStore = false
    },
    async hasBrand() {
      // console.log(this.brandOptions)
      let name = this.formStore.name
      if (name !== '') {
        let params = {}
        params['name'] = name
        if (this.formStore.brand_id) {
          params['id'] = this.formStore.brand_id
        }

        let { exist } = await this.$api.checkBrandName(params)
        this.hasBrandName = exist
        // this.hasBrandName = _.findIndex(this.brandOptions, function(o) {
        //   return o.name == name
        // }) >= 0
      }

    },
    hasAuthority,
    /**
     * 记录当前展开页面
     * @param row
     */

    expandFun(expandedRows, expanded) {
      // this.expands=expanded
      if (expanded.length > 1) {
        this.$refs.groupBonusTable.toggleRowExpansion(expanded[0])
        localStorage.setItem('expandBrand', expandedRows.id)//设置缓存数据，(key,value)
      }
    },
    expandRow(row) {
      this.$refs.groupBonusTable.toggleRowExpansion(row.id)
      this.expands = []
      this.expands.push(row.id)
      localStorage.setItem('expandBrand', row.id)//设置缓存数据，(key,value)
    },
    change() {
      this.$forceUpdate()  //强制刷新
    },
    setSelectValue(type) {
      let option = null
      switch (type) {
        case 'user':
          option = this.contactList.map(i => {
            return i['id'] === this.formStore.contact_user ? i : false
          }).filter(Boolean)
          if (option && option.length != 0) {
            this.formStore.contact_user = option[0].contact_user
            this.$set(this.formStore, 'contact_info', JSON.parse(JSON.stringify(option[0].contact_info)))
          }
          break
        case 'submit':
          if (this.formStore.submit_id) {
            option = this.options.find(item => item.id === this.formStore.submit_name)
            if (option) {
              this.formStore.submit_id = option.id
              this.formStore.submit_name = option.nickname
            }
          }


          break
      }
    },
    async getContact() {
      let data = await this.$api.selectContact({ customer_id: this.formStore.customer_id })
      this.contactList = data.contacts
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.selectLoading = true
        this.options = await this.$api.selectSubmit({ nickname: query })
        this.selectLoading = false
      } else {
        this.options = []
      }
    }
    ,
    linkInfo(id) {
      this.$router.push({
        name: 'product_info',
        params: { id: id }
      })
    }

    ,
    // 图片删除回调
    handleUploadRemove() {
      this.formStore.logo = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },
    handleUploadSuccess(file) {
      this.formStore.logo = file.full_path
    },

    routerPage(name) {
      this.$router.push({
        name: name
      })
    },
    handleEdit(row) {
      this.formStore = {
        brand_id: row.id,
        logo: row.logo,
        remark: row.remark,
        submit_id: row.submit_id,
        submit_name: row.submit_name,
        name: row.name,
        company_name: row.company_name
      }
      this.uploadFileList = [{ url: row.logo }]
      this.custom_type = 'edit'
      this.dialogStore = true

    },
    handleDel(row) {
      let data =
        {
          brand_id: row.id
        }
      this.$confirm(`确认删除【${row.name}】品牌?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.delBrandList(data)
        if (id) {
          this.$message.success('删除成功！')
          await this.getList()
        } else {
          this.$message.error('未知错误,请联系管理员')
        }
      })
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchData?.brand_id !== '') {
        cond.brand_id = this.searchData.brand_id
      } else {
        delete cond.fans_total
      }
      if (this.searchData?.customer_id !== '') {
        cond.customer_id = this.searchData.customer_id
      } else {
        delete cond.customer_id
      }
      this.pagingData.page_size ? cond['page_size'] = this.pagingData.page_size : 10
      this.pagingData.current_page ? cond['current_page'] = this.pagingData.current_page : 10
      return cond
    },
    handleQuery(select) {
      this.searchData = select
      this.pagingData.current_page = 1
      this.getList()
    }
    ,
    async getList() {
      this.isLoading = true
      this.dataList = []
      let params = this.handleSearchCondition()
      let { list, page_info } = await this.$api.getBrandList(params)
      this.dataList = list
      this.pagingData.current_page = page_info.current_page
      this.pagingData.page_size = page_info.page_size
      this.pagingData.total = page_info.total
      let data = localStorage.getItem('expandBrand')
      if (data) {
        this.expands.push(data)
      }
      this.isLoading = false
    }
    ,
    handelAdd() {
      this.custom_type = 'add'
      let userInfo = JSON.parse(localStorage.getItem('user_info'))
      this.$set(this.formStore, 'submit_id', userInfo.id)
      this.$set(this.formStore, 'submit_name', userInfo.nickname)
      this.dialogStore = true
    },
    handelStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          if (!this.formStore.submit_name) {
            let userInfo = JSON.parse(localStorage.getItem('user_info'))
            this.$set(this.formStore, 'submit_id', userInfo.id)
            this.$set(this.formStore, 'submit_name', userInfo.nickname)
          }
          if (!this.formStore.logo) {
            this.formStore.logo = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
          }
          if (!this.hasBrandName) {
            if (this.custom_type === 'add') {
              const params = this.formStore
              await this.$api.addBrand(params)
              this.$message.success('新增成功！')
              this.formatDate()
            } else {
              const params = this.formStore
              await this.$api.updataBrand(params)
              this.$message.success('修改成功！')
              this.formatDate()
            }
          } else {
            this.$message.error('品牌名重复')
            return
          }
          await this.getList()


        }
      })
    }
  },
  mounted() {
    this.getList()

  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 50%;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

//
//
//::v-deep.list-table .el-table thead {
//  background: #eee;
//  color: #666;
//  font-weight: 500;
//  font-size: 14px;
//  line-height: 20px;
//}
//
//::v-deep.list-table .el-table__header-wrapper .el-table__header thead tr th {
//  background: #EBEEF5;
//  padding: 0 0 !important;
//}
//
//::v-deep.list-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
//  background: rgba(248, 248, 248, 1);
//  padding: 0 0 !important;
//}
//
//::v-deep.list-table th, .list-table td {
//  /*padding: 4px 0;*/
//
//}
//
//::v-deep.list-table div.el-table td .cell {
//  height: auto !important;
//  line-height: 20px;
//  font-weight: normal;
//  font-size: 12px;
//
//}
//
///*el-table__cell*/
//::v-deep.list-table thead th .cell {
//  height: 30px;
//  line-height: 30px;
//  font-size: 14px;
//  font-weight: 800;
//  color: #0C0C0C;
//}
//
//::v-deep.list-table-child thead tr th .cell {
//  height: 30px !important;
//  line-height: 30px !important;
//  font-size: 12px !important;
//  /*font-weight: 800 !important; !**!*/
//  color: #0C0C0C !important;
//  font-family: PingFangSC-Regular, sans-serif;
//}
//
//::v-deep.list-table thead th.is-leaf .cell {
//  height: 30px;
//  line-height: 30px;
//  font-size: 14px;
//  font-weight: 800;
//  color: #0C0C0C;
//}
//
//
//::v-deep.list-table-child thead th.is-leaf .cell {
//  height: 30px;
//  line-height: 30px;
//  font-size: 12px;
//  font-weight: normal !important;
//  color: #0C0C0C;
//  font-family: PingFangSC-Regular, sans-serif;
//
//}
//
//::v-deep.list-table-child .el-table__header-wrapper .el-table__header thead tr th {
//  background: #fbf2f5;
//  padding: 0 0 !important;
//}
//
//::v-deep.list-table .el-table thead tr, .list-table .el-table thead th {
//  background: rgb(255, 255, 255) !important;
//  padding: 10px 0 !important;
//  color: rgba(50, 50, 50, 1);
//  font-size: 14px;
//  font-weight: normal;
//
//}
//
//::v-deep.list-table .el-table--striped .el-table__body tr.el-table__row--striped td {
//  background: #EBEEF5;
//}
</style>
